<template>
  <div class="page_container_none_padding">
    <!-- <div id="compony_list">
      <div
        id="compony_info"
        v-for="(info, index) in pages"
        :key="index"
        :style="config[index].style"
      >
        <div id="logos">
          <img
            id="logo"
            v-for="(logo, j) in info.logos"
            :key="`img_${info.name}_${j}`"
            :src="logo"
            v-on:click="handleSwitchTab(index)"
          />
        </div>
        <div
          id="pointer"
          :ref="`point_${index}`"
          :style="{
            'background-color':
              index == selectedIndex ? selectedColor : '#333333',
          }"
          v-on:click="handleSwitchTab(index)"
        ></div>
        <div
          id="compony_name"
          :style="{
            color: index == selectedIndex ? selectedColor : '#333333',
          }"
          v-on:click="handleSwitchTab(index)"
        >
          {{ info.name }}
        </div>
      </div>
    </div> -->
    <NewsPage :items="pages[selectedIndex].details" />
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import NewsPage from "@/components/PC/NewsPage.vue";
import { KPCComponyLayout } from "@/config/news";
import LeaderLine from "leader-line-vue";

@Options({
  components: {
    NewsPage,
  },
  data() {
    return {
      lines: [],
      selectedIndex: 0,
      selectedColor: "#f19000",
      pages: KPCComponyLayout,
      config: [
        {
          name: "上海季芬电子商务有限公司",
          style: {
            left: "390px",
            top: "300px",
          },
        },
        // {
        //   name: "江西归农电子商务有限公司",
        //   style: {
        //     left: "635px",
        //     top: "115px",
        //   },
        // },
        {
          name: "上海蕙芷农业发展有限公司",
          style: {
            left: "5px",
            top: "165px",
          },
        },
        {
          name: "万非(上海)生物科技有限公司",
          style: {
            left: "401px",
            top: "75px",
          },
        },
        {
          name: "香域(上海)医药科技有限公司",
          style: {
            left: "115px",
            top: "30px",
          },
        },
        {
          name: "江西古塘农业发展有限公司",
          style: {
            left: "475px",
            top: "0px",
          },
        },
        // {
        //   name: "上海桑邦文化传播有限公司",
        //   style: {
        //     left: "140px",
        //     top: "300px",
        //   },
        // },
        {
          name: "上海归农信息技术有限公司",
          style: {
            left: "600px",
            top: "270px",
          },
        },
        // {
        //   name: "西云数据科技(上海)有限公司",
        //   style: {
        //     left: "255px",
        //     top: "170px",
        //   },
        // },
        {
          name: "上海购享文化传媒有限公司",
          style: {
            left: "475px",
            top: "180px",
          },
        },
      ],
    };
  },
  methods: {
    handleSwitchTab(index) {
      this.selectedIndex = index;
    },
  },
  mounted() {
    const line = {
      color: "#efefef",
      path: "straight",
      endPlug: "behind",
      size: 1.5,
    };
    console.log(this.$refs);
    // this.lines.push(
    //   LeaderLine.setLine(this.$refs["point_0"], this.$refs["point_1"], line)
    // );
    this.lines.push(
      LeaderLine.setLine(this.$refs["point_0"], this.$refs["point_2"], line)
    );
    this.lines.push(
      LeaderLine.setLine(this.$refs["point_0"], this.$refs["point_3"], line)
    );
    this.lines.push(
      LeaderLine.setLine(this.$refs["point_0"], this.$refs["point_5"], line)
    );
    this.lines.push(
      LeaderLine.setLine(this.$refs["point_0"], this.$refs["point_6"], line)
    );
    this.lines.push(
      LeaderLine.setLine(this.$refs["point_0"], this.$refs["point_7"], line)
    );
    // this.lines.push(
    //   LeaderLine.setLine(this.$refs["point_0"], this.$refs["point_8"], line)
    // );

    // this.lines.push(
    //   LeaderLine.setLine(this.$refs["point_1"], this.$refs["point_3"], line)
    // );
    // this.lines.push(
    //   LeaderLine.setLine(this.$refs["point_1"], this.$refs["point_5"], line)
    // );
    // this.lines.push(
    //   LeaderLine.setLine(this.$refs["point_1"], this.$refs["point_7"], line)
    // );
    // this.lines.push(
    //   LeaderLine.setLine(this.$refs["point_1"], this.$refs["point_8"], line)
    // );
    // this.lines.push(
    //   LeaderLine.setLine(this.$refs["point_1"], this.$refs["point_9"], line)
    // );

    this.lines.push(
      LeaderLine.setLine(this.$refs["point_2"], this.$refs["point_4"], line)
    );
    this.lines.push(
      LeaderLine.setLine(this.$refs["point_2"], this.$refs["point_5"], line)
    );
    this.lines.push(
      LeaderLine.setLine(this.$refs["point_2"], this.$refs["point_6"], line)
    );
    // this.lines.push(
    //   LeaderLine.setLine(this.$refs["point_2"], this.$refs["point_8"], line)
    // );

    this.lines.push(
      LeaderLine.setLine(this.$refs["point_3"], this.$refs["point_4"], line)
    );
    this.lines.push(
      LeaderLine.setLine(this.$refs["point_3"], this.$refs["point_5"], line)
    );
    // this.lines.push(
    //   LeaderLine.setLine(this.$refs["point_3"], this.$refs["point_8"], line)
    // );
    // this.lines.push(
    //   LeaderLine.setLine(this.$refs["point_3"], this.$refs["point_9"], line)
    // );

    this.lines.push(
      LeaderLine.setLine(this.$refs["point_4"], this.$refs["point_5"], line)
    );
    this.lines.push(
      LeaderLine.setLine(this.$refs["point_4"], this.$refs["point_6"], line)
    );
    // this.lines.push(
    //   LeaderLine.setLine(this.$refs["point_4"], this.$refs["point_8"], line)
    // );

    this.lines.push(
      LeaderLine.setLine(this.$refs["point_5"], this.$refs["point_7"], line)
    );

    // this.lines.push(
    //   LeaderLine.setLine(this.$refs["point_6"], this.$refs["point_8"], line)
    // );
    // this.lines.push(
    //   LeaderLine.setLine(this.$refs["point_6"], this.$refs["point_9"], line)
    // );

    // this.lines.push(
    //   LeaderLine.setLine(this.$refs["point_7"], this.$refs["point_8"], line)
    // );
    // this.lines.push(
    //   LeaderLine.setLine(this.$refs["point_7"], this.$refs["point_9"], line)
    // );

    console.log(this.lines);
  },
  beforeUnmount() {
    this.lines.forEach((element) => {
      element.hide();
    });

    this.lines.forEach((element) => {
      element.remove();
    });
    this.lines = [];
  },
})
export default class Page extends Vue {}
</script >

<style scoped lang="scss">
@import url("../../../less/pc/page.less");

#compony_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 400px;
  position: relative;
}

#compony_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  position: absolute;
}

#container {
  width: 100%;
  height: 215px;
}

#logos {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

#logo {
  width: 100px;
  height: 25px;
  z-index: 1001;
  object-fit: contain;
}

#pointer {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  z-index: 1002;
  background-color: #000000;
}

#compony_name {
  z-index: 1003;
  font-size: 6px;
}
</style>